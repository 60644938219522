const config = {
  proxyGraphQLUrl: "https://api.cpp-additiveminds.com/graphql",
  serviceUrl: "https://api.cpp-additiveminds.com/",
  proxyWSGraphQLUrl: "wss://api.cpp-additiveminds.com/graphql",
  AxiosWSUrl: "https://api.cpp-additiveminds.com/cost_calculator",
  // proxyGraphQLUrl: "http://18.220.112.225:3000/graphql",
  // serviceUrl: "http://18.220.112.225:3000/",
  // proxyWSGraphQLUrl: "wss://18.220.112.225:3000/graphql",
  // AxiosWSUrl: "http://18.220.112.225:3000/cost_calculator",
  // AxiosWSUrl : 'https://api.cpp-additiveminds.com/cost_calculator',
  // AxiosWSUrl : 'http://localhost:3000/cost_calculator'
  carbonShadowSuggestionUrl:
    "https://www.worldbank.org/en/news/press-release/2022/05/24/global-carbon-pricing-generates-record-84-billion-in-revenue",
  // proxyGraphQLUrl: 'http://localhost:3000/graphql',
  // serviceUrl: 'http://localhost:3000/',
  // proxyWSGraphQLUrl: 'wss://localhost:3000/graphql',

  //   proxyGraphQLUrl: "http://52.15.128.115:3000/graphql",
  //   serviceUrl: "http://52.15.128.115:3000/",
  //   proxyWSGraphQLUrl: "wss://52.15.128.115:3000/graphql",
  //   AxiosWSUrl: "http://52.15.128.115:3000/cost_calculator",
  // AxiosWSUrl : 'http://localhost:3000/cost_calculator'
};

export default config;
