import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: { backgroundColor: "white", color: "black", fontSize: 14 },
  headerView: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 10,
  },
  logo: { height: 50 },
  header: { width: "35%", fontWeight: "bold", fontSize: 18 },
  address: { width: "25%", fontSize: 12 },
  detailsView: {
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    paddingTop: 0,
  },
  InfoChart: { paddingLeft: 110, paddingRight: 110 },
  partInfoView: { display: "flex", flexDirection: "row" },
  colorPalletePP: { width: 10, height: 10, backgroundColor: "#7761cf" },
  colorPalleteSCPP: { width: 10, height: 10, backgroundColor: "#fcc342" },
  colorPalleteTCPP: { width: 10, height: 10, backgroundColor: "#00bff3" },
  image: { width: "50%" },
  w_100: { width: "100%" },
  w_90: { width: "90%" },
  w_50: { width: "50%" },
  w_40: { width: "40%" },
  w_33: { width: "33.3%" },
  w_30: { width: "30%" },
  w_20: { width: "20%" },
  w_25: { width: "25%" },
  w_15: { width: "15%" },
  w_10: { width: "10%" },
  d_flex: { display: "flex" },
  row: { flexDirection: "row" },
  para_font_size: { fontSize: "10px" },
  tableView: { fontSize: 12 },
  footerView: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
});

const Doc = ({ images, imagesTwo, data, scenarios, isCarbonPricingAvailable, pathNameCheck }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerView}>
          <Image style={styles.logo} src="/assets/logo.png" />
        </View>
        <View style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
          <Text style={{ fontSize: 8, marginBottom: 5, margin: "0 auto" }}>
            COST DISTRIBUTION %
          </Text>
        </View>
        <View style={styles.detailsView}>
          {scenarios.map((image, index) => {
            return (
              <>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: "10px" }}>
                  <Image src={images[index]} style={styles.image} />
                  <Text style={{ fontSize: 10, marginTop: 5, fontWeight: "800" }}>
                    {image}
                  </Text>
                  <Text style={{ fontSize: 8, marginTop: 5 }}>
                    Cost per part
                  </Text>
                  <Text style={{ fontSize: 8, marginTop: 5 }}>
                    System:{" "}
                    {data[scenarios[index]]["system_cost_per_part"]?.value}
                  </Text>
                  {
                    !pathNameCheck && (
                      <Text style={{ fontSize: 8, }}>
                        Recurring:{" "}
                        {data[scenarios[index]]["recurring_cost_per_part"]?.value}
                      </Text>
                    )
                  }

                  <Text style={{ fontSize: 8 }}>
                    Material:{" "}
                    {data[scenarios[index]]["material_cost_per_part"]?.value}
                  </Text>
                  {
                    !pathNameCheck && (
                      <Text style={{ fontSize: 8 }}>
                        Consumables:{" "}
                        {data[scenarios[index]]["cpp_consumables"]?.value}
                      </Text>
                    )
                  }
                  {pathNameCheck ? (
                    <></>
                  ) : (
                    <Text style={{ fontSize: 8 }}>
                      Overhead & Consumables:{" "}
                      {data[scenarios[index]]["cpp_overhead_consumables"]?.value}
                    </Text>
                  )}
                  {isCarbonPricingAvailable && (
                    <Text style={{ fontSize: 8 }}>
                      Carbon Pricing:{" "}
                      {data[scenarios[index]]["cost_of_co2e_per_part"]?.value}
                    </Text>
                  )}
                  <Text style={{ fontSize: 8 }}>
                    {
                      pathNameCheck ?
                        <>
                          Total Cost Per Part:{" "}
                        </>
                        :
                        <>
                          Total AM CPP:{" "}
                        </>
                    }
                    {isCarbonPricingAvailable
                      ? (
                        +data[scenarios[index]]["total_cost_per_part"]?.value
                          .toFixed(2)
                      )
                      :
                      data[scenarios[index]]["total_am_cpp"] ? (
                        data[scenarios[index]]["total_am_cpp"]?.value.toFixed(2)
                      )
                        :
                        data[scenarios[index]]["total_cost_per_part"] ? (
                          data[scenarios[index]]["total_cost_per_part"]?.value.toFixed(2)
                        )
                          :
                          0
                    }
                  </Text>
                  <Text style={{ fontSize: 8 }}>
                    Post Processing:{" "}
                    {
                      data[scenarios[index]]["post_processing_cost_per_part"]
                        ?.value
                    }
                  </Text>
                  {
                    !pathNameCheck && (
                      <Text style={{ fontSize: 8 }}>
                        Overhead:{" "}
                        {
                          pathNameCheck ? <>{data[scenarios[index]] && data[scenarios[index]]["cpp_overhead"] ? + data[scenarios[index]]["cpp_overhead"]?.value : ""} </> : data[scenarios[index]] && data[scenarios[index]]["cpp_overhead"] && + data[scenarios[index]]["cpp_overhead"]?.value ? <>{+ data[scenarios[index]]["cpp_overhead"]?.value}</> : 0

                        }
                      </Text>
                    )
                  }

                </View>
                {
                  !pathNameCheck &&
                  <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Image src={imagesTwo[index]} style={styles.image} />
                    <Text style={{ fontSize: 10, marginTop: 5, fontWeight: "800" }}>
                      {image}
                    </Text>
                    <Text style={{ fontSize: 8, marginTop: 5 }}>
                      Cost per cm3
                    </Text>
                    <Text style={{ fontSize: 8, marginTop: 5 }}>
                      System:{" "}
                      {data[scenarios[index]]["system_cost_per_cm3"]?.value}
                    </Text>
                    <Text style={{ fontSize: 8 }}>
                      Recurring:{" "}
                      {data[scenarios[index]]["recurring_cost_per_cm3"]?.value}
                    </Text>
                    <Text style={{ fontSize: 8 }}>
                      Material:{" "}
                      {data[scenarios[index]]["material_cost_per_cm3"]?.value}
                    </Text>
                    <Text style={{ fontSize: 8 }}>
                      Consumables:{" "}
                      {data[scenarios[index]]["consumable_cost_per_cm3"]?.value}
                    </Text>
                    <Text style={{ fontSize: 8, marginBottom: 8 }}>
                      Total AM Cost per cm3:
                      {isCarbonPricingAvailable
                        ? (
                          data[scenarios[index]]["total_am_cost_cm3"] ?
                            +data[scenarios[index]]["total_am_cost_cm3"]?.value
                              .toFixed(2)
                            : 0)
                        :
                        data[scenarios[index]]["total_am_cost_cm3"] ? (

                          data[scenarios[index]]["total_am_cost_cm3"]?.value.toFixed(2)
                        )
                          :
                          0
                      }
                    </Text>
                    {isCarbonPricingAvailable && (
                      <Text style={{ fontSize: 8 }}>
                        Carbon Pricing:{" "}
                        {data[scenarios[index]]["cost_of_co2e_per_part"]?.value}
                      </Text>
                    )}
                  </View>
                }

              </>
            );
          })}
        </View>
        {/* {

        <View style={styles.InfoChart}>
          {
            scenarios.map((scenario, ind) => {
              return (
                <>
                  <Text style={{ fontSize: 12 }}>System:  {data[scenario]["system_cost_per_part"]?.value}</Text>
                </>
              )
            })
          }
        </View>
      } */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 150px",
            justifyContent: 'center'
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 5,
              marginTop: 3,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: "#556679",
                marginRight: 5,
              }}
            ></View>
            <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
              System
            </Text>
          </View>
          {
            !pathNameCheck && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 5,
                  marginTop: 3,
                }}
              >
                <View
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: "#7daebc",
                    marginRight: 5,
                  }}
                ></View>
                <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
                  Recurring
                </Text>
              </View>
            )
          }

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 5,
              marginTop: 3,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: !pathNameCheck ? "#777777" : "#7daebc",
                marginRight: 5,
              }}
            ></View>
            <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
              Material
            </Text>
          </View>
          {
            !pathNameCheck &&
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 5,
                marginTop: 3,
              }}
            >
              <View
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#0095c8",
                  marginRight: 2,
                }}
              ></View>
              <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
                Consumables
              </Text>
            </View>
          }
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 5,
              marginTop: 3,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: "#9ea6b4",
                marginRight: 2,
              }}
            ></View>
            <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
              Post Processing
            </Text>
          </View>

          {
            isCarbonPricingAvailable &&
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 5,
                marginTop: 3,
              }}
            >
              <View
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#00bff3",
                  marginRight: 2,
                }}
              ></View>
              <Text style={{ color: "#000", fontSize: 10, marginRight: 5 }}>
                Carbon Pricing
              </Text>
            </View>
          }
        </View>
      </Page>
    </Document>
  );
};

function DognutPdfGenerator({
  images,
  scenarios,
  imagesTwo,
  data,
  isCarbonPricingAvailable,
  pathNameCheck
}) {
  console.log(scenarios, 'scenarios');

  return (
    <div>
      {images && (
        <BlobProvider
          document={
            <Doc
              images={images}
              imagesTwo={imagesTwo}
              data={data}
              scenarios={scenarios}
              isCarbonPricingAvailable={isCarbonPricingAvailable}
              pathNameCheck={pathNameCheck}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            if (!loading) {
              window.open(url, "_blank");
            }

            return "";
          }}
        </BlobProvider>
      )}
    </div>
  );
}

export default DognutPdfGenerator;
