import React from "react";
import { Chart } from "react-google-charts";
import { useState } from "react";
import BCPPPdfGenerator from "./BCPPPdfGenerator";
import { useAccess } from "state/hooks";
import { overHeadConsumables } from "atoms/General/index.js";
import { useRecoilState } from "recoil";
import { CCMetalCostOfCo2PerPartFDataM } from "atoms/CCCarbonMetal/CCMetalPricing";
import { useHistory } from "react-router-dom";
import { Technology_Name } from "utils/constantenums";

const BCPPGraph = (data) => {
  let Access = useAccess();
  const history = useHistory();
  const [GoogleChart, setGoogleChart] = useState();
  let [bcppDownloadPdf, setbcppDownloadPdf] = useState(false);
  const [headconsumables, setHeadConsumables] =
    useRecoilState(overHeadConsumables);
  const [metalCo2ePerPart, setMetalCo2ePerPart] = useRecoilState(
    CCMetalCostOfCo2PerPartFDataM
  );
  const [polyCo2ePerPart, setPolyCo2ePerPart] = useRecoilState(
    CCMetalCostOfCo2PerPartFDataM
  );
  let technology_name = sessionStorage.getItem("technologyName");


  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const CurrencyObj = currencies.find((x) => x.name === currency);
  const labelArray = [
    "",
    "System",
    "Material",
    "Post Processing",
  ];
  const labelArray2 = [
    "",
    "System",
    "Recurring",
    "Material",
    "Consumables",
    "Post Processing",
    "Overhead",
  ];
  if ((history.location.pathname === "/calculator/visual-analytics/true" || history.location.pathname === "/calculator/visual-analytics/false") && (sessionStorage.getItem("technologyName") === "Metal (Estimation)" || sessionStorage.getItem("technologyName") === "Polymer (Estimation)")) {
    setHeadConsumables(0)

  }
  else {
    setHeadConsumables(window.localStorage.getItem("CPP OC"));

  }

  const dataSet = [];
  if (history.location.pathname === "/calculator/visual-analytics/true") {
    setMetalCo2ePerPart(0);
    dataSet.push(Access ? headconsumables ? labelArray2 : labelArray : ["", "System Cost", "Material Cost"]);
  } else if (Number(sessionStorage.getItem("Carbon")) === 0) {
    setMetalCo2ePerPart(0);
    dataSet.push(Access ? headconsumables ? labelArray2 : labelArray : ["", "System Cost", "Material Cost"]);
  } else {
    setMetalCo2ePerPart(window.localStorage.getItem("metalCo2pp"));
    setPolyCo2ePerPart(window.localStorage.getItem("metalCo2pp"));
    dataSet.push(
      Access
        ? [
          "",
          "System",
          "Recurring",
          "Material",
          "Consumables",
          "Post Processing",
          "Overhead",
          "Carbon Pricing",
        ]
        : ["", "System Cost", "Material Cost"]
    );
  }

  const renderBarChart = () => {
    const scenarios = data.scenarios;
    const formFields = data.formFields;

    // dataSet.push(Access ? ['','System Cost', 'Material Cost', 'Post Processing', 'Overhead & Consumables', 'Cost Of CO2e/Part'] : ['', 'System Cost', 'Material Cost']);
    for (let index = 0; index < scenarios.length; index++) {
      if (formFields[scenarios[index]] && formFields[scenarios[index]]["hetrojobs"] && formFields[scenarios[index]]["hetrojobs"].length > 1) {
        dataSet[0] =
          ((metalCo2ePerPart !== undefined || polyCo2ePerPart !== undefined) && history.location.pathname !== "/calculator/visual-analytics/true") ?
            [
              "",
              "System",
              "Recurring",
              "Material",
              "Consumables",
              "Carbon Pricing",
            ]
            :
            [
              "",
              "System",
              "Recurring",
              "Material",
              "Consumables",
            ]
        for (let i = 0; i < formFields[scenarios[index]]["hetrojobs"].length; i++) {
          const hetroJobs = formFields[scenarios[index]]["hetrojobs"]
          let multiple_parts_value
          if (technology_name === Technology_Name.POLYMER_SIMULATION_STORAGE) {
            multiple_parts_value = parseFloat(hetroJobs[i]?.part_volume)
          }
          else if (technology_name === Technology_Name.METAL_SIMULATION) {
            multiple_parts_value = parseFloat(hetroJobs[i]?.part_volume) + parseFloat(hetroJobs[i]?.part_support_volume)
          }
          const arrData = [];

          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["total_am_cost_cm3"]
          ) {
            if (Access) {
              if (metalCo2ePerPart) {
                arrData.push(`${scenarios[index]} (Total AM CPP: ${parseFloat(formFields[scenarios[index]]["total_am_cost_cm3"]?.value * multiple_parts_value).toFixed(2)} ${CurrencyObj.currency_symbol}) Part ${i + 1}`
                );
              }
              else {
                arrData.push(`${scenarios[index]} (Total AM CPP: ${parseFloat(formFields[scenarios[index]]["total_am_cost_cm3"]?.value * multiple_parts_value).toFixed(2)} ${CurrencyObj.currency_symbol}) Part ${i + 1}`);
              }
            } else {
              arrData.push(`${scenarios[index]} (Total AM CPP: ${(parseFloat(formFields[scenarios[index]]["total_am_cost_cm3"]?.value * multiple_parts_value)).toFixed(2)} ${CurrencyObj.currency_symbol}) Part ${i + 1}`);
            }
          }
          if (technology_name === Technology_Name?.METAL_ESTIMATION || technology_name === Technology_Name?.POLYMER_ESTIMATION) {
            if (formFields[scenarios[index]] && formFields[scenarios[index]]["total_cost_per_part"]) {
              arrData.push(
                `${scenarios[index]} (Total Cost: ${(
                  parseFloat(
                    formFields[scenarios[index]]["total_cost_per_part"]?.value
                  )
                ).toFixed(2)} ${CurrencyObj.currency_symbol} )`
              );
            }
          }
          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["system_cost_per_cm3"]
          ) {
            arrData.push(
              parseFloat(formFields[scenarios[index]]["system_cost_per_cm3"].value * multiple_parts_value)
            );
          }
          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["recurring_cost_per_cm3"]
          ) {
            arrData.push(
              parseFloat(formFields[scenarios[index]]["recurring_cost_per_cm3"].value * multiple_parts_value)
            );
          }
          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["material_cost_per_cm3"]
          ) {
            arrData.push(
              parseFloat(
                formFields[scenarios[index]]["material_cost_per_cm3"].value * multiple_parts_value
              )
            );
          }
          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["consumable_cost_per_cm3"]
          ) {
            arrData.push(
              parseFloat(
                formFields[scenarios[index]]["consumable_cost_per_cm3"].value * multiple_parts_value
              )
            );
          }
          if ((metalCo2ePerPart !== undefined || polyCo2ePerPart !== undefined) && history.location.pathname !== "/calculator/visual-analytics/true") {
            if (
              formFields[scenarios[index]] &&
              formFields[scenarios[index]]["cost_of_co2e_per_part"]
            ) {
              arrData.push(
                parseFloat(
                  formFields[scenarios[index]]["cost_of_co2e_per_part"].value * multiple_parts_value
                )
              );
            }
          }
          dataSet.push(arrData);
        }
      }
      else {
        const arrData = [];

        if (
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["total_am_cpp"]
        ) {
          if (Access) {
            if (metalCo2ePerPart) {
              arrData.push(
                `${scenarios[index]} (Total AM CPP: ${parseFloat(formFields[scenarios[index]]["total_am_cpp"]?.value).toFixed(2)} ${CurrencyObj.currency_symbol})`
              );
            }
            else {
              arrData.push(`${scenarios[index]} (Total AM CPP: ${parseFloat(formFields[scenarios[index]]["total_am_cpp"]?.value).toFixed(2)} ${CurrencyObj.currency_symbol})`);
            }
          } else {
            arrData.push(`${scenarios[index]} (Total AM CPP: ${(parseFloat(formFields[scenarios[index]]["total_am_cpp"]?.value)).toFixed(2)} ${CurrencyObj.currency_symbol})`);
          }
        }
        if (technology_name === Technology_Name?.METAL_ESTIMATION || technology_name === Technology_Name?.POLYMER_ESTIMATION) {
          if (formFields[scenarios[index]] && formFields[scenarios[index]]["total_cost_per_part"]) {
            arrData.push(
              `${scenarios[index]} (Total Cost: ${(
                parseFloat(
                  formFields[scenarios[index]]["total_cost_per_part"]?.value
                )
              ).toFixed(2)} ${CurrencyObj.currency_symbol} )`
            );
          }
        }
        if (
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["system_cost_per_part"]
        ) {
          arrData.push(
            parseFloat(formFields[scenarios[index]]["system_cost_per_part"].value)
          );
        }
        if (
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["recurring_cost_per_part"]
        ) {
          arrData.push(
            parseFloat(formFields[scenarios[index]]["recurring_cost_per_part"].value)
          );
        }
        if (
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["material_cost_per_part"]
        ) {
          arrData.push(
            parseFloat(
              formFields[scenarios[index]]["material_cost_per_part"].value
            )
          );
        }
        if (
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["cpp_consumables"]
        ) {
          arrData.push(
            parseFloat(
              formFields[scenarios[index]]["cpp_consumables"].value
            )
          );
        }
        if (
          Access &&
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["post_processing_cost_per_part"]
        ) {
          arrData.push(
            parseFloat(
              formFields[scenarios[index]]["post_processing_cost_per_part"].value
            )
          );
        }
        if (
          Access &&
          formFields[scenarios[index]] &&
          formFields[scenarios[index]]["cpp_overhead"]
        ) {
          arrData.push(
            parseFloat(
              formFields[scenarios[index]]["cpp_overhead"].value
            )
          );
        }
        if ((metalCo2ePerPart !== undefined || polyCo2ePerPart !== undefined) && history.location.pathname !== "/calculator/visual-analytics/true") {
          if (
            formFields[scenarios[index]] &&
            formFields[scenarios[index]]["cost_of_co2e_per_part"]
          ) {
            arrData.push(
              parseFloat(
                formFields[scenarios[index]]["cost_of_co2e_per_part"].value
              )
            );
          }
        }
        // if (formFields[scenarios[index]] && formFields[scenarios[index]]['total_cost_per_part']) {
        //   arrData.push(parseFloat(formFields[scenarios[index]]['total_cost_per_part'].value));
        // }
        dataSet.push(arrData);
      }
    }

    // Data Set used for drawing Graphs
    return (
      <>
        <p className="figure-title">
          BUILD COST (PER PART){" "}
          <button
            onClick={() => {
              //setDognutDownloadPdf(true);
              // console.log(GoogleChart.getChart().getImageURI());
              setbcppDownloadPdf(true);
              setTimeout(() => {
                setbcppDownloadPdf(false);
              }, 2000);
            }}
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>{" "}
        </p>
        <div className="chartholder" style={{ marginBottom: "5%" }}>
          <Chart
            getChartWrapper={(chartWrapper) => {
              setGoogleChart(chartWrapper);
            }}
            key={scenarios}
            graphID="bcpp"
            width={"900px"}
            height={`${200 * scenarios.length}px`}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={dataSet}
            options={{
              width: "100%",
              chartArea: { width: "46%", height: "70%" },
              backgroundColor: { fill: "#eeeeed" },
              displayAnnotations: true,
              isStacked: true,
              colors: Access
                ? ["#556679", "#b36ca4", "#7daebc", "#b37b6c", "#9ea6b4", "#0095c8", "#00677d"]
                : ["#fcc342", "#00bff3", "#7daebc"],
              // Material design options
              chart: {
                title: "",
                subtitle: "",
              },
              bars: "horizontal",
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </>
    );
  };
  return (
    <>
      {bcppDownloadPdf === true && GoogleChart && (
        <BCPPPdfGenerator image={GoogleChart.getChart().getImageURI()} />
      )}
      {renderBarChart()}
    </>
  );
};

export default BCPPGraph;
