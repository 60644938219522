import config from "./../config";
import gql from "graphql-tag";
const GET_STEPS = gql`
  query getClientSteps($technology_id: Int!, $type_id: Int!) {
    getClientSteps(technology_id: $technology_id, type_id: $type_id) {
      name
      description
      step_id
      selected_icon
      unselected_icon
      technology_id {
        technology_id
        set_up_time
        utilization
        name
        status
        is_blocked
        unpacking_time_per_job
      }
      step_type_id {
        step_type_id
        name
        description
      }
    }
  }
`;
const GET_STEPS_FIELDS = gql`
  query getFieldsByStepId($step_id: Int!) {
    getFieldsByStepId(step_id: $step_id) {
      field_id
      name
      keys
      field_data_type
      data_reference
      formula_inputs
      field_unit
      field_info
      field_type
      field_input_type
      formula
      is_premium
      is_override_enabled
      is_optional
      renderer
      field_step_index
      is_customizable
      technology_id {
        technology_id
      }
      step_type_id {
        step_type_id
      }
      step_id {
        step_id
      }
    }
  }
`;

const GET_MATERIALS = gql`
  query getClientMaterialByMachineId(
    $machine_id: Int!
    $group_id: Int!
    $calculator_type: String!
  ) {
    getClientMaterialByMachineId(
      machine_id: $machine_id
      group_id: $group_id
      calculator_type: $calculator_type
    ) {
      material_id
      name
      is_data_avail_for_cost
      is_data_avail_for_carbon
    }
  }
`;

const GET_MACHINES = gql`
  query getClientMachineByTechnology(
    $technology_id: Int!
    $calculator_type: String!
  ) {
    getClientMachineByTechnology(
      technology_id: $technology_id
      calculator_type: $calculator_type
    ) {
      machine_id
      name
      is_data_avail_for_cost
      is_data_avail_for_carbon
    }
  }
`;

const GET_PARAMETERS = gql`
  query getClientParemeterByMMIds($machine_id: Int!, $material_id: Int!) {
    getClientParemeterByMMIds(
      machine_id: $machine_id
      material_id: $material_id
    ) {
      paremeter_id
      name
    }
  }
`;

const GET_FILL_RATES = gql`
  query getClientFillRates(
    $machine_id: Int!
    $material_id: Int!
    $parameter_name: String!
  ) {
    getClientFillRates(
      machine_id: $machine_id
      material_id: $material_id
      paremeter_name: $parameter_name
    ) {
      paremeter_id
      fill_rate_type
    }
  }
`;
const GET_FILTERS = gql`
  query getClientFilters($machine_id: Int!) {
    getClientFilters(machine_id: $machine_id) {
      filter_id
      name
      filter_type
    }
  }
`;

const GET_RECOATER_BLADES = gql`
  query getClientRacoaterBlades($machine_id: Int!) {
    getClientRacoaterBlades(machine_id: $machine_id) {
      racoater_blade_id
      recoater_blade_name
    }
  }
`;

const GET_INERT_GAS = gql`
  query getClientInertGas {
    getClientInertGas {
      gas_id
      name
    }
  }
`;
const GET_BUILD_PLATFORM = gql`
  query getClientBuildPlatforms($machine_id: Int!) {
    getClientBuildPlatforms(machine_id: $machine_id) {
      build_platform_id
      build_platform_name
    }
  }
`;
const HETROGENEOUS = "hetrojobs";
const DEFAULT_SCENARIO = "Default";

const GET_SUB_FIELDS_BY_ID = gql`
  query getSubFieldsByFieldId($field_id: Int!) {
    getSubFieldsByFieldId(field_id: $field_id) {
      field_id
      name
      field_unit
      field_info
      field_type
      field_input_type
      formula_inputs
      is_override_enabled
      is_optional
    }
  }
`;

export default {
  GET_STEPS,
  uri: config.AxiosWSUrl,
  GET_MACHINES,
  GET_MATERIALS,
  GET_PARAMETERS,
  GET_STEPS_FIELDS,
  HETROGENEOUS,
  GET_FILL_RATES,
  GET_FILTERS,
  GET_RECOATER_BLADES,
  GET_INERT_GAS,
  GET_BUILD_PLATFORM,
  DEFAULT_SCENARIO,
  GET_SUB_FIELDS_BY_ID
};

